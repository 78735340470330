import {Box, Button, ButtonGroup, Flex, Heading, Link, Spacer} from "@chakra-ui/react";
import * as React from "react";
import {Logo} from "./Logo";

const HeaderComponent: React.FC = () => {
    return (
        <>
            <Flex minWidth='max-content' alignItems='center' gap='2' height='70px'>
                <Box p='2' px='5'>
                    <Heading size='md'>
                        <Logo/>
                    </Heading>
                </Box>
                <Spacer/>
                <ButtonGroup gap='2' p='2' px='5'>
                    <Button colorScheme='teal'>
                        <Link href='https://pay2free.readme.io' isExternal>
                            Api Reference
                        </Link>
                    </Button>
                </ButtonGroup>
            </Flex>
        </>

    )
}

export default HeaderComponent;