import {useEffect, useState} from "react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import * as React from "react";
const readmePath = require("./p2fdoc.md");

const Documentation: React.FC = () => {

    const [data, dataSource] = useState<any>();
    useEffect(() => {
        fetch(readmePath)
            .then(response => {
                return response.text()
            })
            .then(text => {
                dataSource(text)
                console.log(text)
            })
    })
    return (
        <MarkdownPreview source={data} />
    )
}

export default Documentation;

