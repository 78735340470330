import * as React from "react"
import {
    ChakraProvider,
    theme, Flex,
} from "@chakra-ui/react"
import HeaderComponent from "./componenets/Header";
import Documentation from "./documentation";


export const App = () => (
    <ChakraProvider theme={theme}>
        <HeaderComponent />
        <Flex background="#0d1117" px="5" pt="10" alignItems="center" justifyContent="center" >
            <Documentation />
        </Flex>
    </ChakraProvider>
)
